*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior:smooth;
}
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
.slideshowcontainer
{
  
  height: 100%;
  width: 100%;
  /* margin: 0 auto; */
}
::-webkit-scrollbar {
  display: none;
}