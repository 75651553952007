/* Keyframes for menu slide animations */
@keyframes slideIn {
    from {
        opacity: 0;
        right: -200px;
    }
    to {
        opacity: 1;
        right: 0px;
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        right: 0px;
    }
    to {
        opacity: 0;
        right: -200px;
    }
}

/* Navigation container styling */
.nav-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 70px;
    background: none;
    margin-top: 2vh;
    z-index: 2;
    justify-content: space-between;
    padding: 0 20px;
}
.nav-container.relative{
  position: relative;
  background: linear-gradient(to right,#00093c,#2d0b00);
  margin-top: auto;
}

/* Logo styling */
.nav-logo img {
    width: 175px;
    height: 100%;
    background-size: contain;
    background-position: center;
    margin-left: 20px;
}

/* Hide the checkbox by default */
.menu-btn {
    display: none;
}

/* Navigation items styling */
.nav-items {
    display: flex;
    padding-right: 5px;
    gap: 35px;
    outline: none !important;
    text-transform: uppercase;
    transition: 0.2s;
}

/* Navigation links styling */
.nav-items a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    align-self: center;
    font-size: 21px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    display: flex;
    align-items: flex-end;
    text-align: left;
    position: relative;
}

/* Icon styling */
.icons {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

/* Underline effect on hover */
.nav-button::after {
    content: "";
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    height: 3px;
    width: 0;
    transition: width 0.2s;
}

.nav-button:hover,
.nav-button:focus {
    color: #ffffff;
    transform: scale(1.1);
}

.nav-button:hover::after,
.nav-button:focus::after {
    background-color: #ffffff;
    width: 100%;
}

.nav-button:hover span, 
.nav-button:focus span {
    text-decoration: underline;
}

/* Hamburger menu icon styling */
.menu-icon {
    cursor: pointer;
    padding: 28px 20px;
    position: relative;
    user-select: none;
    display: none; /* Hide the hamburger icon initially */
}

.nav-icon {
    background-color: #ffffff;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 33px;
}

.nav-icon::before,
.nav-icon::after {
    background: rgb(255, 255, 255);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
}

.nav-icon::before {
    top: 6px;
}

.nav-icon::after {
    top: -6px;
}

/* Styles when the menu button is checked */
.menu-btn:checked ~ .menu-icon .nav-icon {
    background: transparent;
}

.menu-btn:checked ~ .menu-icon .nav-icon::before {
    transform: rotate(-45deg);
    top: 0;
}

.menu-btn:checked ~ .menu-icon .nav-icon::after {
    transform: rotate(45deg);
    top: 0;
}

/* Responsive design for screens */

/* Mobile screens */
@media screen and (max-width: 767px) {
    .nav-logo img {
        width: 150px;
    }

    .nav-items {
      display: none;
    }

    .menu-icon {
        display: block;
        z-index: 2;
    }

    .menu-btn:checked ~ .nav-items {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px;
        right: 0;
        background-color: #4159c0;
        width: 100%;
        padding: 10px 0;
        align-items: center; /* Center align items */
    }

    .nav-items a {
        padding: 15px;
        font-size: 18px;
        display: flex;
        align-items: end;
        justify-content: flex-start; /* Align items to start */
        width: 100%; /* Full width for consistent start point */
        max-width: 200px; /* Adjust max width as needed */
    }

    .nav-items.slide-in {
        animation: slideIn 0.5s forwards;
    }

    .nav-items.slide-out {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px; 
        right: 0px;
        background-color: #4159c0;
        width: 100%;
        padding: 10px 0;
        animation: slideOut 1.5s forwards;
    }

    .nav-button::after {
        bottom: 0;
    }

    .nav-items a img {
        align-self: center;
        margin-right: 10px;
    }
    .nav-button:hover::after,
    .nav-button:focus::after {
        display: none;
    }

}

/* Tablet screens */
@media screen and (min-width: 768px) and (max-width: 1039px) {
    .nav-logo img {
        width: 150px;
    }

    .nav-items {
        display: none;
    }

    .menu-icon {
        display: block;
        z-index: 2;
    }

    .menu-btn:checked ~ .nav-items {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px; 
        right: 0px;
        background-color: #4159c0;
        width: 100%;
        padding: 10px 0;
        align-items: center;
    }

    .nav-items a {
        width: 100%;
        padding: 15px;
        font-size: 23px;
        display: flex;
        align-items: end;
        justify-content: flex-start;
        width: 200px;
    }

    .nav-items.slide-in {
        animation: slideIn 0.5s forwards;
    }

    .nav-items.slide-out {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px; 
        right: 0px;
        background-color: #4159c0;
        width: 100%;
        padding: 10px 0;
        animation: slideOut 1.5s forwards;
    }

    .nav-button::after {
        bottom: 0;
    }

    .nav-items a img {
        align-self: center;
        margin-right: 10px;
        width: 35px;
    height: 35px;
    }
    
    .nav-button:hover::after,
    .nav-button:focus::after {
        display: none;
    }
}

/* Screens between 1024px and 1099px */
@media screen and (min-width: 1040px) and (max-width: 1100px) {
    .nav-logo img {
        width: 170px;
    }

    .nav-items {
        display: flex;
        gap: 20px;
    }

    .menu-icon {
        display: none;
    }

    .nav-items a {
        font-size: 16px;
    }

    .icons {
        width: 20px;
        height: 25px;
    }
}
/* Screens between 1100px and 1300px */
@media screen and (min-width: 1101px) and (max-width: 1350px) {
    .nav-logo img {
        width: 170px;
    }

    .nav-items {
        display: flex;
        gap: 20px;
    }

    .menu-icon {
        display: none;
    }

    .nav-items a {
        font-size: 18px;
    }

    .icons {
        width: 25px;
        height: 25px;
    }
}

/* Laptop screens */
@media screen and (min-width: 1351px) and (max-width: 1620px) {
    .nav-logo img {
        width: 175px;
    }

    .nav-items {
        display: flex;
        gap: 28px;
    }

    .menu-icon {
        display: none;
    }

    .nav-items a {
        font-size: 21px;
    }
}

/* Large screens */
@media screen and (min-width: 1621px) {
    .nav-logo img {
        width: 200px;
    }

    .nav-items {
        display: flex;
    }

    .menu-icon {
        display: none;
    }

    .nav-items a {
        font-size: 24px;
    }
}
