footer{
    width: 100%;
    background: linear-gradient(to right,#00093c,#2d0b00);
    position: relative;
    bottom: 0;
    color: #fff;
    border-top-left-radius: 125px;
    font-size: 13px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 20px;
    padding: 100px 0 30px;
}
.row{
    display: flex;
    margin: auto;
    flex-direction: row;
    width: 85%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.col
{
    flex-basis: 25%;
    padding: 10px;
}
.col:nth-child(2)
{
    flex-basis: 20%;
}
.col:nth-child(3){
    flex-basis: 15%;
}
.logo{
    width: 170px;
    margin-bottom: 30px;
}
.col h3{
    width: fit-content;
    margin-bottom: 30px;
    position: relative;
}
.email-id{
    width: fit-content;
    border-bottom: 1px solid white;
    margin: 20px 0;
}
.landline{
    display: flex;
    margin: 10px 0;
    gap: 1rem;
}
.mobile{
    display: flex;
    margin: 10px 0;
    gap: 1.3rem;
}

ul li{
   list-style: none;
   margin-bottom: 12px;
}
ul li a{
    text-decoration: none;
    color: white;
}
hr
{
    width: 90%;
    border-bottom: 1px solid white  ;
    margin: 20px auto;
}
.copyright{
    text-align: center;
}
.underline
{
    width: 100%;
    height: 5px;
    background: #9e9e9e;
    border-radius: 3px;
    position: absolute;
    top:25px;
    left:0;
    overflow: hidden;
}
.underline span{
    width: 15px;
    height: 100%;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    top:0;
    left:10px;
    animation: moving 2s linear infinite;
}
@keyframes moving {
    0%{
        left:-20px;
    }
    100%{
        left:100%;
    }
    
}
@media (max-width:700px) {
    footer{
        bottom: unset;
    }
    
.col
{
    flex-basis: 100%;
}
.col:nth-child(2),.col:nth-child(3)
{
    flex-basis: 100%;
}
}
