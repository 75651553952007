/* CSS/BookApointment.css */

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    margin-bottom: 50px;
  }

.details{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.formItem
{
    display: flex;
    gap: 30px;
    padding: 25px 0;
}
.circle{
    width:90px;
    height:90px;
    background-color: #174ad7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.submit
{
    width:100%;
    height:50px;
    background-color: #174ad7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 120px;
}
.icon
{
    color: white;
    font-size: 3rem;
}
.input
{
    font-size: 15px;
    width: 60%;
    background: transparent;
    border-radius: 40px;
    border: 0;
    outline: none;
    font-size: x-large;
    padding-left: 20px;
    background-color: #f8f8f8;
}.textarea {
    width: 80%;
    height: 150px;
    background: transparent;
    border-radius: 50px;
    border: 0;
    outline: none;
    font-size: 25px;
    padding-left: 20px;
    padding-top: 60px; /* Added padding-top to align with the placeholder */
    background-color: #f8f8f8;
    resize: none;
    box-sizing: border-box; /* Ensures padding is included in the width and height */
}

.textarea::placeholder {
    font-size: 25px;
    color: #888888;
    padding-left: 20px; /* Same padding as the textarea */
    padding-top: 5px; /* Same padding as the textarea */
    box-sizing: border-box; /* Ensures padding is included in the width and height */
}

  

button {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: white;
    padding: 20px;
  }
  