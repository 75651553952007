.message-sent-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .message-sent {
    background-color: #4caf50; /* Green background */
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .message-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background-color: white;
    width: 0;
    animation: fillLine 2s linear forwards;
    overflow: hidden;
  }
  
  @keyframes fillLine {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  