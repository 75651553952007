.background{
    width: 100%;
    top:0;
}
.background img{
    width: 100%;
    height: 100%;
    background-size:cover; 
    background-repeat: no-repeat;
    background-position: right;
    filter:brightness(70%);
    /* -webkit-filter: blur(2px); */
    transition: ease-in-out 1s;
    /* background-attachment: fixed; */
    clip-path: polygon(0% 0%, 100% 0%,100% 70%,0% 90% );
}
.background h2{
    position: absolute;
   top:50%;
   left: 40%;
   font-size: 50px;
   font-family:monospace;
   font-weight: 800;
   color: #ffffff;
   text-decoration: none;
   
}
.background p{
    position: absolute;
    top:60%;
    left: 28%;
    font-size: 30px;
    font-family:monospace;
   font-weight: 500;
   color: #ffffff;
   text-decoration: none;

}
.Contact-container{
    width: 90%;
    position: relative;
    background-color: rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    margin-top: -16%;
    color: #000000;
    border-radius: 85px;
    font-size: 13px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.Contact-row{
    display: flex;
    margin: auto;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

}
.Contact-col
{
    flex-basis: 45%;
    padding: 50px 40px 30px;
    border-top-left-radius: 85px;
    border-bottom-left-radius: 85px;

}
.Contact-col:nth-child(1)
{
    flex-basis: 45%;
    background:#f8f8f8;
}
.Contact-col:nth-child(2)
{
    flex-basis: 50%;
}
.Contact-col h2{
    text-align:left;
    color:#1f3983 ;
    font-size:40px;
   
}
.Contact-col p{
    
    text-align: left;
    color:#333333 ;
    font-size:20px;
    margin: 10px 0;
}
.Contact-col1-item{
    display: flex;
    align-items: flex-start;
    gap: 30px;
    padding: 25px 0;
}
.circle{
    min-width:90px;
    min-height:90px;
    background-color: #174ad7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.circle-icon
{
    color: white;
    font-size: 3rem;
}
.Contact-col1-data{
    max-width: 75%;
}
.Contact-col1-data h4{
    font-size: 30px;
    color:#1f3983 ;
    margin-bottom: 10px;
}
.Contact-col1-data p{
    margin: 3px 0;
}
iframe
{
        border: 0;
        width: 100%;
        height: calc(100vh - 20px); 
        margin-top:100px;
}
@media (max-width:750px) {
    .Contact-row{
        flex-direction: row;
    }
    .Contact-col:nth-child(1)
{
    flex-basis: 100%;
    background:#f8f8f8;
}
.Contact-col:nth-child(2)
{
    flex-basis: 100%;
}
}