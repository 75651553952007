.slidecontainer {
   top: 0;
    width: 100%;
    height:100vh;
  }
  
  .slideshow{
    width: 100%;
    height: 100%;
    background-size:cover; 
    background-repeat: no-repeat;
    background-position: right;
    filter:brightness(60%);
    /* -webkit-filter: blur(2px); */
    transition: ease-in-out 1s;
    /* background-attachment: fixed; */
   
  }
/* Slideshow.css */

.slide-data {
  width:400px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slide-data.animate {
  opacity: 1;
}
.slide-data img {
  width:200px;
  transform: translateY(400px);
  opacity: 0;
  transition: transform 1s ease-out, opacity 1s ease-in-out;
}
.slide-data h1{
  transform: translateY(200px);
  opacity: 0;
  transition: transform 2s ease-out, opacity 1s ease-in-out;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.slide-data p{
  transform: translateY(150px);
  opacity: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: transform 3s ease-out, opacity 1s ease-in-out;
}
.slide-data button {
  transform: translateY(100px);
  opacity: 0;
  transition: transform 1s ease-out, opacity 1s ease-in-out;
}
.slide-data.animate img, .slide-data.animate h1, .slide-data.animate p , .slide-data.animate button{
  transform: translateY(0);
  opacity: 1;
}
.learn-more-button{
  padding: 0px;
  width: auto;
  margin: 40px 0 0 20px;
}

.learn-more-button a{
  background: transparent;
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-family:sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease, border-color 0.3s ease; /* Combine transitions */  display: inline-block;
  border-radius: 20px;
}

.learn-more-button a:hover {
  /* background: white; */
  transform: scale(1.1); 
  border-color: transparent; /* Hide the original border */
  box-shadow: 0 0 3px 4px rgba(255, 255, 255, 0.8); /* Blue glowing gradient effect */
  /* font-weight: 700; */
  color: rgb(255, 255, 255); /* Change to desired hover text color */
}

/* Other styles remain unchanged */


  /* slide button */
  .circlecontainer {
    gap: 5px;
    display: flex;
    justify-content: center;
    position: absolute;
    /* bottom: 1; Align to the bottom */
    top:90%;
    right: 50%;
    left: 50%; /* Center horizontally */
    /* transform: translateX(-50%); Correct for centering */
  }
  .button-container {
    position: relative;
    /* left: 40%;
    top: 80%; */
    /* transform: translate(0%, -50%); */

  }
  
  .button.round {
    position: relative;
    width: 1.5em;
    height: 1.5em;
    border-radius: 4em;
    transition: color 0.1s, border 0.1s, transform 0.1s;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.8);
    outline: none;
  
    &.loading span:not(.loading-text),
    &.loading i {
      opacity: 0;
    }
    &.loading:after {
      border-radius: 0;
      transform: translate(-50%, -50%) rotate(45deg);
      border: none;
    }
    &.loading:hover:before {
      transform: translate(-50%, -20%) rotate(45deg);
      opacity: 0.1;
    }
    &.loading:before {
      border-radius: 0;
      transform: translate(-50%, -40%) rotate(45deg);
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0.1;
    }
  
    &:before {
      content: " ";
      opacity: 0;
      width: 0%;
      height: 0%;
      border-radius: 4em;
      transition: width 0.2s, height 0.2s, opacity 0.2s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
    }
    &:after {
      content: " ";
      width: 100%;
      height: 100%;
      border-radius: 30em;
      transition: width 0.3s, height 0.3s, opacity 0.3s, border-radius 0.3s, transform 0.3s;
      border: 1px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover:before {
      opacity: 1;
      width: 100%;
      height: 100%;
    }
    &.button--hold:before {
      width: 95%;
      height: 95%;
      opacity: 0.9;
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0, 0, 0, 1);
    }
    &:hover:after {
      width: 180%;
      height: 180%;
      opacity: 0;
    }
    &.button--click:after {
      animation: pulsate 0.4s ease-out 1;
    }
  }
  .button.round.button-click {
    background-color: white;
     animation: pulsate 0.4s ease-out 1;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

@media (max-width:750px) {
  .slide-data.animate{
    width: 300px;
  }
  .slide-data.animate img{
    width: 150px;
  }
  .slide-data.animate h1{
    font-size: 25px;
  }
  .slide-data.animate p{
    font-size: 20px;
  }
  .slide-data.animate button{
    padding: 0px;
    width: auto;
    margin: 40px 0 0 20px;
  }
  .slide-data.animate button a{
    font-size: 15px;
   }
 
  
}

@media (min-width:751px ) and (max-width:1599px) {
  .slide-data.animate{
    width: 500px;
  }
  .slide-data.animate img{
    width: 240px;
  }
  .slide-data.animate h1{
    font-size: 30px;
  }
  .slide-data.animate p{
    font-size: 15px;
  }
  .slide-data.animate button{
    padding: 0px;
    width: auto;
    margin: 40px 0 0 20px;
  }
 
}
@media (min-width:1600px) {
  .slide-data.animate{
    width: 600px;
  }
  .slide-data.animate img{
    width: 250px;
  }
  .slide-data.animate h1{
    font-size: 55px;
  }
  .slide-data.animate p{
    font-size: 20px;
  }
  .slide-data.animate button{
    padding: 0px;
    width: auto;
    margin: 40px 0 0 20px;
  }
  
}