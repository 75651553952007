/* CSS/BookApointment.css */

.form {
    display: flex;
    align-items: end;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    margin-bottom: 50px;
  }

.details{
    display: flex;
    flex-direction: column;
    flex-basis: 80%;
}
.formItem
{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}
.icon
{
    height: 25px;
    width: 25px;
    font-size: 18px;
    margin: 0 20px 0px 0;
}
.input
{
    font-size: 15px;
    width: 100%;
    background: transparent;
    color: #ccc;
    border: 0;
    outline: none;
}
.textarea
{
    font-size: 15px;
    width: 100%;
    background: transparent;
    color: #ffffff;
    border: 0;
    outline: none;
    resize: none;
}


  

button {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: white;
    padding: 10px;
    flex-basis: 20%;
  }
  