.Whatsapp-container {
    width: 67px;
    height: 67px;
    position: fixed;
    bottom: 40px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #06a261;
    border-radius: 50%; /* Always have border radius */
    transition: background 0.3s, border-radius 0.3s, transform 0.3s;
}

a {
    cursor: pointer;
    text-decoration: none; /* Remove underline from link */
}

.whatsapp {
    color: white;
    font-size: 50px;
    transition: color 0.3s, transform 0.3s;
}

.container:hover {
    transform: scale(1.1); /* Zoom effect */
}

.whatsapp:hover {
    color: rgb(83, 233, 103); /* Maintain white color on hover */
}

@media (max-width:750px)
{
    .container {
        width: 50px;
        height: 50px;
    }
    .whatsapp{
        font-size: 40px;
    }
}