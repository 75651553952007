.Intro-container{
    width: 100%;
    position: relative;
    background-color: rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    /* margin-top: -16%; */
    color: #000000;
    /* border-radius: 85px; */
    font-size: 13px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
}

.Intro-row{
    display: flex;
    margin: auto;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content:center;
    align-items: flex-start;
    padding: 60px 0 0 0;

}
.Intro-col
{
    flex-basis: 45%;
    padding: 50px 40px 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.Intro-col:nth-child(1)
{
    padding-left: 80px;
    flex-basis: 45%;
}
.Intro-col:nth-child(2)
{
    flex-basis: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.IntroBackground img{
    max-width:100%;
    height: auto;
}
.Intro-col h4{
    color: rgba(0, 0, 255, 0.788);
    font-size: 20px;
    margin-bottom: 40px;
}
.Intro-col h1{
    color: rgba(8, 8, 68, 0.834);
    font-size: 40px;
    margin-bottom: 30px;
}
.Intro-col p{
    color: rgba(8, 8, 68, 0.834);
    font-size: 20px;
    margin-bottom: 30px;
}
.Intro-col-items
{
    display: flex;
    align-items: flex-start;
    gap: 30px;
    padding: 15px 0;
}
.Intro-col-item1{
    min-width:90px;
    min-height:90px;
    background-color: #174ad7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.Intro-icon{
    color: rgb(255, 255, 255);
    font-size: 3rem;
}
.Intro-col-item2 h2{
    font-size: 30px;
}
@media (max-width:750px) {
.Intro-row{
    flex-direction: row;
}
.Intro-col:nth-child(1)
{
    padding-left: 40px;
    flex-basis: 100%;
}
.Intro-col:nth-child(2)
{
    flex-basis: 100%;
}
.Intro-col-items{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Intro-col-item2 h2,.Intro-col-item2 p{
    text-align: center;
}
}