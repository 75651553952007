.container
{
    width: 67px;
    height: 67px;
}
.SocialIcons
{
    display: flex;
    justify-content: space-between;
}
.Icons {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    margin-right: 5px;
    cursor: pointer;
    color: white;
}