.Count-container{
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
}
.Count-row{
    padding: 50px 0;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:100px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}
.Count-col
{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 0;
    color: #174ad7;
}
.Count-col h1{
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.Count-col p{
    text-align: center;
    font-size: 20px;
    color: rgb(39, 39, 39);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media (max-width:750px) {
 .Count-row
 {
    padding: 50px 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:100px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    }
    .Count-col{
        padding: 0 0;
    }
}