@keyframes slides {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.project-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    background-size: 100% 100%; /* Maintain aspect ratio and cover */
    background-position: center;
    background-color:#005AD9;
    background-image: url(https://web.moxcreative.com/kassapay/wp-content/uploads/sites/10/2023/02/bg_img_1.png);
    /* background: linear-gradient(to bottom right, #4eb0f2, #005d9b); Gradient from light blue to dark blue */
    /* background: linear-gradient(to right,#001d6d,#174ad7); */
    background-attachment: fixed;
}
.project-container-data
{
    display: flex;
    flex-direction: row;
}

.heading
{
    text-align: center;
    color: white;
    font-size: 60px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 20px 0 0 0;
}
.project-container::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0),  rgb(71, 151, 237));
    content: "";
    z-index: 2;
    filter: brightness(50%);
}

.project-container::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 170px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0),  rgb(71, 151, 237));
    content: "";
    z-index: 2;
    filter: brightness(50%);
}

.project-container-slide {
    display: flex;
    white-space: nowrap;
    padding: 130px 0;
    animation: 25s slides infinite linear;
}

.project-container-slide img {
    height: 200px;
    margin: 0 40px;
    transition: transform 0.2s; /* Add transition for smooth scaling */
}



.Project-items {
    display: block;
    text-decoration: none;
    margin: 0 40px;
}

.Project-items h3 {
    margin-top: 45px;
    text-align: center;
    color: white;
    position: relative; /* Make sure the underline is positioned relative to the h3 */
    padding-bottom: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.Project-items h3::after { /*underline element*/
    content: "";
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 0;
    left: 50%; /* Center the underline */
    transform: translateX(-50%); /* Center horizontally */
    height: 1px; /* Height/thickness of underline */
    width: 100%; /*initial width is 0 then do 100 on hover */
    transition: width 0.2s; /* Transition only the width */
    filter: brightness(80%);
}

.project-items-details {
    white-space: break-spaces;
    text-decoration: none;
    color: white;
    padding-top: 20px;
}

.project-items-details li {
    margin-top: 20px;
    padding-left: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: large;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    letter-spacing: 1px;
}

.Project-items-details::marker {
    unicode-bidi: isolate;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    font-variant-numeric: tabular-nums;
    text-align-last: start !important;
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
  

    .project-container-slide img {
        height: 140px;
        /* margin: 20px 0; */
    }

    .Project-items {
        margin: 0 20px;
    }

    .Project-items h3 {
        font-size: 18px;
    }

    .project-items-details li {
        font-size: 15px;
    }
    
    .project-container::after , .project-container::before{
    display: none;
}
    
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
 

    .project-container-slide img {
        height: 180px;
        margin: 0 30px;
    }

    .Project-items {
        margin: 0 30px;
    }

    .Project-items h3 {
        font-size: 22px;
    }

    .project-items-details li {
        font-size: large;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1600px) {
    .project-container-slide img {
        height: 200px;
        margin: 0 35px;
    }

    .Project-items {
        margin: 0 35px;
    }

    .Project-items h3 {
        font-size: 24px;
    }

    .project-items-details li {
        font-size: medium;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1750px) {
    .project-container-slide img {
        height: 230px;
        margin: 0 45px;
    }

    .Project-items {
        margin: 0 45px;
    }

    .Project-items h3 {
        font-size: 30px;
    }

    .project-items-details li {
        font-size: large;
    }
}


@media screen and (min-width: 1751px ) {
    .project-container-slide img {
        height: 260px;
        margin: 0 60px;
    }

    .Project-items {
        margin: 110px 60px;
    }

    .Project-items h3 {
        font-size: 35px;
    }

    .project-items-details li {
        font-size: larger;
    }
}

