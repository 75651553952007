.services-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #f7f7f7;
    background: linear-gradient(135deg, #f0f0f0 0%, #eaeaea 100%);

    background-size: 100% 100%;
    background-position: center;
    justify-content: center;
    /* background-attachment: fixed; */
    padding: 20px 0 0 0;
}
.services-container h1
{
    text-align: center;
    font-size: 60px;
    color: #005AD9;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
}
.services-grid {
    display: grid;
    grid-template-columns: 1fr; /* Default: 1 column for mobile view */
    /* grid-gap: 40px; */
    width: 100%;
    justify-items: center;
}

.services-card {
    display: block;
    height: 450px;
    width: 90%; /* Default width for mobile view */
    max-width: 350px; /* Max width to maintain a consistent card size */
    margin: 10% 5%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 10px rgba(73, 43, 193, 0.1);
    transition:  box-shadow 0.6s ease;
}

.services-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.top-card {
    width: 100%;
    height: 55%;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-image {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit:cover; /* Ensure image covers the entire area */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.bottom-card {
    background-color:#fafafa;
    height: 45%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center text vertically */
    align-items: center; /* Center text horizontally */
    text-align: center; /* Center text */
}

.bottom-card h3 {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #005AD9;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); 
    margin: 0;
    padding-top: 50px;
}

.bottom-card p {
    padding: 10px 20px;
    color: #053b87;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    letter-spacing: 0.5px;
    margin: 10px 0 0 0; /* Add margin at the top of the paragraph */
    line-height: 1.5; /* Improve readability */
}

/* Media query for tablets */
@media (max-width: 840px) {
    .services-grid {
        grid-template-columns: repeat(1, 1fr); /* 1 column for tablets */
    }
    .services-card {
        width: 80%; /* Adjust card width for tablets */
        height: 450px; /* Adjust card height for tablets */
        
    }

    .bottom-card h3 {
        font-size: 1.25rem;
    }
    .bottom-card p {
        font-size: 0.9rem;
        padding: 10px 10px;
    }
}

/* Media query for laptops */
@media (min-width: 841px) and (max-width: 1304px) {
    .services-grid {
        grid-template-columns: repeat(3, 1fr); /* 2 columns for laptops */
    }
    .services-card {
        width: 80%; /* Adjust card width for desktops */
        height: 500px; /* Adjust card height for desktops */
        max-width: 350px;
    }
    .bottom-card h3 {
        font-size: 1.5rem;
    }
    .bottom-card p {
        font-size: 1rem;
    }
}

/* Media query for desktops */
@media (min-width: 1305px) and (max-width: 1700px) {
    .services-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for desktops */
        /* grid-gap: 10px; */
    }
    .services-card {
        width: 85%; /* Adjust card width for desktops */
        height: 560px; /* Adjust card height for desktops */
        max-width: 500px;
    }
    .bottom-card h3 {
        font-size: 1.75rem;
    }
    .bottom-card p {
        font-size: 1.1rem;
    }
}

/* Media query for very large screens */
@media (min-width: 1701px) {
    .services-grid {
        grid-template-columns: repeat(3, 1fr); /* 4 columns for very large screens */
    }
    .services-card {
        width: 100%; /* Adjust card width for very large screens */
        height: 650px; /* Adjust card height for very large screens */
        max-width: 450px;
    }
    .bottom-card h3 {
        font-size: 2rem;
    }
    .bottom-card p {
        font-size: 1.2rem;
    }
}
